<template>
	<div class="home">
		<v-card>
			<v-container fluid>

                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <h3>Trades Event Notification</h3>
                        <v-alert text class="mt-2" color="info" icon="mdi-cloud-alert" border="left">
                            <p class="mb-0">The system will monitor logins' trading actions including: open/close order. Once the event is triggered, a message will be pushed to telegram channel.</p>
                            <p class="mb-0"><strong>1. Login</strong>: The login added to monitor on trade action.
                            </p>
                            <p class="mb-0"><strong>2. Telegram</strong>: Telegram group chat id that will show the notification.</p>
                            <p class="mb-0"><strong>3. Active</strong>: Deactive - Do not show the message in tele. Active - Show the message in tele.</p>
                        </v-alert>
                    </v-col>
                </v-row>

                <v-data-table :headers="headers" :items="tradesConfig" :loading="tradesLoading"
                    loading-text="Loading... Please wait" dense :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="#1e1e1e">
                            <v-dialog v-model="newConfigDialog" max-width="700px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" style="left:-15px"
                                        autocomplete="off">
                                        New Config
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h5">New Config</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col lg="12" md="12" xs="12" cols="12">
                                                <v-text-field v-model="newTradesConfig.login" label="Client Target Login" class="mt-0 mb-0"
                                                    ref="login" autocomplete="off">
                                                </v-text-field>
                                            </v-col>
                                            <v-col lg="12" md="12" xs="12" cols="12">

                                                <v-autocomplete label="Telegram Channel (Which channel will the notification be sent to)"
                                                    v-model="newTradesConfig.telegram" :items="telegramList" item-text="text" item-value="id"
                                                    auto-select-first chips deletable-chips small-chips></v-autocomplete>

                                                <!-- <v-text-field v-model="newTradesConfig.telegram" label="Telegram"
                                                    :rules="[rules.required]" required ref="telegram" class="mt-0 mb-0"
                                                    autocomplete="off">
                                                </v-text-field> -->
                                            </v-col>
                                            <v-col lg="12" md="12" xs="12" cols="12">
                                                <v-checkbox v-model="newTradesConfig.active" dense class="mt-0 mb-0"
                                                    :label="`Active: ${newTradesConfig.active?'Yes':'No'}`">
                                                </v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="newTradesConfigClose">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="newTradesConfigSave"
                                            :disabled="newTradesConfig.login==''||newTradesConfig.telegram==''">
                                            Save
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                hide-details></v-text-field>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title class="text-h5">Sure to delete the login?</v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeTradesConfigDelete">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="deleteTradesConfigConfirm">OK</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.active="{ item }">
                        <v-simple-checkbox v-model="item.active" :key="item.login" @click="updateTradesActive(item)"
                            dense :ripple="false">
                        </v-simple-checkbox>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon @click="deleteTradesConfigDialog(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                    <template v-slot:item.telegram="props">
                        <v-edit-dialog :return-value.sync="props.item.telegram" large persistent
                            @save="updateTradesTele(props.item)">
                            <div>{{ props.item.telegram }}</div>
                            <template v-slot:input>
                                <div class="mt-4 text-h6">Update Telegram</div>
                                <v-text-field v-model="props.item.telegram" label="Edit" single-line counter autofocus />
                            </template>
                        </v-edit-dialog>
                    </template>
                </v-data-table>
			</v-container>
		</v-card>
	</div>
</template>
<script>
import { snackbar } from "@components/mixins/snackbar";
import { permission } from "@components/mixins/permission";
import * as apiNotifyTrades from "@components/api/pk/settings/notify-trades";
import { mapState } from "vuex";

export default {
    props: {
        broker: null,
        brokerId: null
    },
    components: {},
    mixins: [permission, snackbar],
    data () {
        return {
            telegramList: [
                { text: "Telegram Target Channel (Client's Own Trading Account)", id: -407130980 },
                { text: "Telegram BFJ/Sigma Channel (Controlled by Sigmatm Team)", id: -423006565 }
            ],
            tradesConfig: [],
            tradesLoading: false,
            headers: [
                { text: "Login", align: "start", value: "login" },
                { text: "Telegram", value: "telegram" },
                {
                    text: "Active",
                    value: "active",
                    sort: function (a, b) {
                        if (a ^ b) {
                            return a && !b ? -1 : 1;
                        } else {
                            return 0;
                        }
                    }
                },
                { text: "Actions", value: "actions", sortable: false }
            ],
            newConfigDialog: false,
            dialogDelete: false,
            newTradesConfig: {
                telegram: -407130980,
                login: "",
                active: 1
            },
            defaultNewTradesConfig: {
                telegram: "",
                login: "",
                active: 0
            },
            rules: {
                required: (value) => !!value || "Required",
                login: (value) =>
                    !this.tradesConfig.some((o) => o.login === value) ||
                    "Login already exists"
            },
            editedItem: -1,
            deleteTradesConfig: {
                telegram: "",
                login: "",
                active: 0
            },
            search: ""
        };
    },
    watch: {
        newConfigDialog (val) {
            val || this.newTradesConfigClose();
        },
        dialogDelete (val) {
            val || this.closeTradesConfigDelete();
        }
    },
    methods: {

        /**
         * 获取不同broker对应的trades config
         */
        loadTradesConfig () {
            const self = this;
            const params = {
                broker: self.broker
            };
            this.tradesLoading = true;
            apiNotifyTrades.fetch(params).then((res) => {
                self.tradesConfig = res.data;
                this.tradesLoading = false;
                console.log(res.data);
                for (const i in self.tradesConfig) {
                    self.tradesConfig[i].active = Boolean(self.tradesConfig[i].active);
                }
            });
        },
        /**
         * 更新trades config的active设定
         */
        updateTradesActive (item) {
            const self = this;
            item.telegram = Number(item.telegram);
            item.login = Number(item.login);
            item.active = Number(item.active);
            const params = {
                broker: self.broker,
                telegram: item.telegram,
                login: item.login,
                active: item.active
            };
            console.log(params);
            apiNotifyTrades.update(params).then((res) => {
                let message = "Active Successfully";
                if (item.active == 0) message = "Deactive Successfully";

                setTimeout(() => {
                	self.snackBarSuccess(message);
                }, 300);
            });
        },
        /**
         * 更新trades config的telegram设定
         */
        updateTradesTele (item) {
            const self = this;
            console.log(item);
            item.telegram = Number(item.telegram);
            item.login = Number(item.login);
            item.active = Number(item.active);
            const params = {
                broker: self.broker,
                telegram: item.telegram,
                login: item.login,
                active: item.active
            };
            console.log(params);
            apiNotifyTrades.update(params).then((res) => {
                const message = "Update Tele ID Successfully";

                setTimeout(() => {
                	self.snackBarSuccess(message);
                }, 300);
            });
        },
        /**
         * 关闭new config的dialog，关闭时进行输入内容情况+验证情况重置
         */
        newTradesConfigClose () {
            const self = this;
            self.newConfigDialog = false;
            self.newTradesConfig.login = null;
            // self.$nextTick(() => {
            //     self.newTradesConfig = Object.assign(
            //         {},
            //         self.defaultNewTradesConfig
            //     );
            // self.resetValidation();
            // });
        },
        // /**
        //  * 重置required验证情况
        //  */
        // resetValidation() {
        //     this.$refs.login.resetValidation();
        //     this.$refs.telegram.resetValidation();
        // },
        /**
         * 新建trades config
         */
        newTradesConfigSave () {
            const self = this;
            self.newTradesConfig.telegram = Number(self.newTradesConfig.telegram);
            self.newTradesConfig.login = Number(self.newTradesConfig.login);
            self.newTradesConfig.active = Number(self.newTradesConfig.active);
            const params = {
                broker: self.broker,
                telegram: self.newTradesConfig.telegram,
                login: self.newTradesConfig.login,
                active: self.newTradesConfig.active
            };
            console.log(params);
            apiNotifyTrades.create(params).then((res) => {
                const message = "Create Login Successfully";

                setTimeout(() => {
                	self.snackBarSuccess(message);
                    self.loadTradesConfig();
                }, 300);
            });
            // self.tradesConfig.push(self.newTradesConfig);
            self.newTradesConfigClose();
        },
        /**
         * 关闭删除trades config对话框
         */
        closeTradesConfigDelete () {
            const self = this;
            self.dialogDelete = false;
            self.$nextTick(() => {
                self.deleteTradesConfig = Object.assign(
                    {},
                    self.defaultNewTradesConfig
                );
                self.editedIndex = -1;
            });
        },
        /**
         * 打开删除trades config对话框，对item进行定位
         */
        deleteTradesConfigDialog (item) {
            const self = this;
            self.deleteIndex = self.tradesConfig.indexOf(item);
            self.deleteTradesConfig = Object.assign({}, item);
            self.dialogDelete = true;
        },
        /**
         * 确认删除trades config
         */
        deleteTradesConfigConfirm () {
            const self = this;
            self.tradesConfig.splice(self.deleteIndex, 1);
            const params = {
            	broker: self.broker, login: self.deleteTradesConfig.login
            };

            console.log(params);

            apiNotifyTrades.remove(params).then((res) => {
                const message = "Delete Login Successfully";
	            self.closeTradesConfigDelete();

                setTimeout(() => {
                	self.snackBarSuccess(message);
                	self.loadTradesConfig();
                }, 300);
            });
        }
    },
    mounted () {
        this.loadTradesConfig();
    }
};
</script>
